@font-face {
	font-family: 'Archivo Variable';
	src: url(assets/archivo-variable.woff2) format('woff2');
}

@font-face {
	font-family: 'Archivo Italic';
	src: url(assets/archivo-italic.woff2) format('woff2');
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: inherit;
	font-weight: inherit;
	margin: 0;
}

a {
	color: inherit;
}

button {
	font: inherit;
	padding: 0;
	background: none;
	border: none;
}
