.tweakpane-element {
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 100;
}

.tweakpane-handle {
	/*height: 30px;*/
	background-color: #222;
	display: grid;
	gap: 3px;
	justify-content: stretch;
	padding: 6px 6px 0 6px;
	cursor: pointer;
}

.tweakpane-handle span {
	height: 1px;
	width: 20px;
	background-color: #555;
}

body .tp-rotv {
	--tp-base-background-color: #222;
	--tp-base-border-radius: 0;
	--tp-container-unit-spacing: 10px;
	--tp-container-unit-size: 30px;
	--tp-blade-value-width: 260px;
	--tp-base-font-family: sans-serif;
	font-size: 12px;
}
