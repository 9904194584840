.Main {
	position: relative;
	background-color: white;
	padding: var(--padding);
	font-size: 18px;
	font-variation-settings:
		'wght' 600,
		'wdth' 125;
}

.Main a {
	text-decoration: none;
}

.Main a:hover {
	opacity: 0.8;
}

.Inner {
	display: grid;
	padding: 1.875rem;
	border-radius: var(--radius);
	color: white;
	background-color: black;
}

.Logo {
	padding: 0 0 9.375rem 0;
}

.Title {
	padding: 0 0 2em 0;
}

.Claim {
	padding: 0 0 3.5em 0;
	max-width: 20ch;
	display: grid;
	gap: 1.5em;
}

.Links {
	display: grid;
	justify-content: start;
	justify-items: start;
	padding: 0 0 2em 0;
}

.Copyright {
	display: grid;
	white-space: pre-line;
}

.SMS {
	width: 80%;
	position: relative;
	aspect-ratio: 357/45;
	box-sizing: border-box;
}

.SMS svg {
	position: absolute;
	width: 100%;
	height: 100%;
	fill: currentColor;
}

@media (min-width: 768px) {
	.Main {
		padding: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.Inner {
		padding: 2.5vw calc(var(--padding) - 1.25rem);
		grid-template-columns: repeat(3, minmax(0, max-content));
		justify-content: end;
		gap: 0 5vw;
		grid-auto-flow: row dense;
	}

	.Logo {
		padding: 1.25rem 0 9.375rem 0;
	}

	.Title {
		grid-row: 2;
		padding: 0 0 1.3em 0;
	}

	.Claim {
		grid-column: 1;
		grid-row: 3;
		padding: 0;
	}

	.Links {
		grid-column: 2;
		grid-row: 3;
		padding: 0;
	}

	.Copyright {
		grid-column: 3;
		grid-row: 3;
	}
}
