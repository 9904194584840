.SlideInDown {
	animation-fill-mode: both;
	animation-duration: 1000ms;
	animation-timing-function: var(--ease-out-expo);
}

.SlideFadeInDown {
	animation-fill-mode: both;
	animation-duration: 1000ms;
	animation-timing-function: var(--ease-out-expo);
}

.ScaleFadeIn {
	animation-fill-mode: both;
	animation-duration: 1000ms;
	animation-timing-function: var(--ease-out-expo);
}
