@keyframes FadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes FadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes ScaleFadeIn {
	0% {
		opacity: 0;
		transform: scale(0.9);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes ScaleInLeft {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

@keyframes SlideInDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes SlideFadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-1em);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes SlideFadeInRight {
	0% {
		opacity: 0;
		transform: translateX(1em);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes SlideFadeInDownSmall {
	0% {
		transform: translateY(-0.5em);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
