.hljs-attr,
.hljs-attribute {
	color: #5f6fff;
}

.hljs-string,
.hljs-meta .hljs-string {
	color: #757575;
}

.hljs-number,
.hljs-class {
	color: #cccccc;
}

.hljs-keyword,
.hljs-literal,
.hljs-symbol,
.hljs-name {
	color: #af5fff;
}
