.Main {
	fill: currentColor;
	aspect-ratio: 120/27;
	width: clamp(80px, 70px + 2.5vw, 120px);
}

/*@media (min-width: 768px) {*/
/*	.Main {*/
/*		width: 6.25vw;*/
/*	}*/
/*}*/
