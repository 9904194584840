.Label {
	font-size: clamp(0.875rem, 0.7794rem + 0.3922vw, 1.25rem);
	font-variation-settings: 'wght' 900;
}

.Copy {
	font-size: clamp(0.875rem, 0.7794rem + 0.3922vw, 1.25rem);
	line-height: 1.3;
	letter-spacing: 0.02em;
	font-variation-settings:
		'wght' 300,
		'wdth' 100;
}

.Copy h1,
.Heading1 {
	line-height: normal;
	font-size: clamp(2.125rem, 1.4286rem + 2.8571vi, 6rem);
	font-variation-settings:
		'wght' 400,
		'wdth' 125;
}

.Copy h2,
.Heading2 {
	line-height: normal;
	font-size: clamp(1.75rem, 1.4314rem + 1.3072vw, 3rem);
	font-variation-settings:
		'wght' 400,
		'wdth' 125;
}

.Copy h3,
.Heading3 {
	line-height: normal;
	font-size: clamp(1.25rem, 1.0588rem + 0.7843vw, 2rem);
	font-variation-settings:
		'wght' 400,
		'wdth' 125;
}

.Copy h1,
.Copy h2,
.Copy h3,
.Copy h4,
.Copy h5,
.Copy h6 {
	padding: 1em 0 0.5em 0;
}

.Copy p {
	padding: 0 0 1.5em 0;
}

.Copy p:last-child {
	padding: 0;
}
