.Main {
	position: relative;
	padding: 2.5rem var(--padding);
	display: grid;
	justify-content: center;
	background-color: white;
}

.Copy {
	max-width: 75ch;
}
